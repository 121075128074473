import { Form, FormatText, FormInput, FormSubmitButton, notification, Row, useRouter } from '@digital/ui';
import { Button, Card, Icon, Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import Background from '../../../assets/images/background.png';
import LoginBoxLogo from '../../../assets/images/logo_medium.png';
import { LoginProps, onLoginUser } from '../../api/auth';
import { LostPassProps, onLostPass } from '../../api/user';
import { useActions } from '../../store/models';

export default () => {
  const { history } = useRouter();
  const [lostPass, setLostPass] = useState(false);
  const { userAuthenticated, loadPermissions } = useActions(actions => actions.permission);
  const { onChangePassword, onRefreshLogin, onEstablishmentId, onUserCode, onUserData, onRepresentativeId, onUserToken } = useActions(actions => actions.app);
  const { setHasMenu, setHasHeader } = useActions(actions => actions.ui);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const antIcon = <Icon type="loading" style={{ fontSize: 18, color: '#FFFFFF' }} spin />;

  useEffect(() => {
    setLoadingLogin(false);
    setHasMenu(false);
    setHasHeader(false);
  }, []);

  const handleSubmit = async (values: LoginProps) => {
    setLoadingLogin(true);
    onLoginUser(values).then(resp => {
      if (resp) {
        localStorage.setItem('token-sales_portal', resp.tokenAccess);
        userAuthenticated({
          accessToken: resp.tokenAccess,
          username: resp.user.Name,
        });
        onUserToken(resp.tokenAccess);
        onRefreshLogin(true);
        loadPermissions([resp.permission]);
        onEstablishmentId(resp.user.EstablishmentId);
        onRepresentativeId(resp.user.RepresentativeId);
        onUserCode(resp.user.UserCode);
        if (!resp.user.PasswordUpdatedAt) {
          onChangePassword(true);
        }
        onUserData({
          Name: resp.user.Name,
          Email: resp.user.Email,
        })
        setHasHeader(true);
        sessionStorage.setItem('app.sales-portal', JSON.stringify({
          establishmentId: resp.user.EstablishmentId,
          token: resp.tokenAccess
        }))
        setTimeout(() => history.push('/'), 300);
      } else {
        setLoadingLogin(false);
      }
    });
  };

  const handleLostPass = async (values: LostPassProps) => {
    setLoadingLogin(true);
    onLostPass(values).then(resp => {
      if (resp) {
        notification.success('', resp.msg);
        setLostPass(false);
      }
      setLoadingLogin(false);
    });
  };

  return (
    <div className="login-view">
      <div className="login-view" style={{height: '100%',  backgroundImage: `url(${Background})`, width: '100%'}}></div>
      <Layout>
        <Card title={<img width={180} alt="login-box-logo" src={LoginBoxLogo} />}>
          {lostPass ? (
            <Form onSubmit={handleLostPass}>
              <Row style={{ margin: 0 }}>
                <FormatText id="Lembra da senha?" />
                <Button className="forgot-password" onClick={() => setLostPass(false)}>
                  <FormatText id="Faça login!" />
                </Button>
              </Row>
              <Row style={{ margin: 0, marginTop: 10 }}>
                <FormInput label="Email" name="email" placeholder="Email" />
              </Row>
              <FormSubmitButton
                noIcon
                type="primary"
                htmlType="submit"
                style={{
                  width: '100px',
                  height: '40px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontWeight: 'bold',
                  marginTop: 15,
                }}>
                {loadingLogin ? antIcon : <FormatText id="app.changePass.send" />}
              </FormSubmitButton>
            </Form>
          ) : (
            <Form onSubmit={handleSubmit}>
              <Row style={{ margin: 0 }}>
                <FormInput label="ui.commons.user" name="user" placeholder="ui.commons.user" />
              </Row>
              <Row style={{ margin: 0, marginTop: 10 }}>
                <div style={{ position: 'absolute', right: 0, zIndex: 10 }}>
                  <Button className="forgot-password" onClick={() => setLostPass(true)}>
                    <FormatText id="Esqueci minha senha!" />
                  </Button>
                </div>
                <FormInput
                  label="ui.commons.password"
                  name="password"
                  type="password"
                  placeholder="ui.commons.password"
                />
              </Row>
              <FormSubmitButton
                noIcon
                type="primary"
                htmlType="submit"
                style={{
                  width: '100px',
                  height: '40px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontWeight: 'bold',
                  marginTop: 15,
                }}>
                {loadingLogin ? antIcon : <FormatText id="ui.commons.login" />}
              </FormSubmitButton>
            </Form>
          )}
        </Card>
      </Layout>
    </div>
  );
};
